.pageheader {
  padding: 0 5vw;
  min-height: calc(80vh - 200px);
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 80px;
  padding-bottom: 80px;
}

.pageheader.pageheadermeta {
  grid-template-columns: 1fr 2fr;
}

.pageheader.centered,
.pageheader.pageheadermeta.centered {
  grid-template-columns: 1fr;
  text-align: center;
  padding-top: 140px;
}

.pageheader.centered div,
.pageheader.pageheadermeta.centered div {
  align-items: center;
  text-align: center;
}

.pageheader.hero,
.pageheader.pageheadermeta.hero {
  min-height: calc(80vh - 200px);
  padding-top: 100px;
  grid-template-columns: 1fr;
  align-items: center;
  align-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  margin: 0 auto;
  white-space: pre-wrap;
}

.pageheader.hero div h1 {
  width: 1100px;
  margin-top: 0;
  font-size: 120px;
  max-width: 100%;
  line-height: 110%;
  font-weight: 400;
}

.pageheader.pageheadermeta.hero div:last-child {
  margin-bottom: 0;
}

.pageheader.hero div p {
  margin-top: revert;
  margin-bottom: revert;
  width: 100%;
  font-weight: 400;
  white-space: pre-wrap;
  max-width: none;
}

.pageheader div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.pageheader div p {
  max-width: 560px;
  margin-bottom: 0;
  margin-top: 220px;
  white-space: pre-wrap;
}

.pageheader.centered div p,
.pageheader.pageheadermeta.centered div p {
  max-width: 740px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.pageheader.pageheadermeta div:last-child {
  margin-bottom: 20px;
}

.pageheaderlinks li {
  margin-bottom: 6px;
}

.pageheader.coverPhoto {
  min-height: 300px;
}

.spacer {
  margin-top: 84px;
}

@media only screen and (max-width: 1180px) {
  .pageheader.hero,
  .pageheader.pageheadermeta.hero {
    min-height: calc(120px + 30vh);
    align-content: flex-end;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pageheader.hero div h1 {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 56px;
    max-width: min(700px, 90vw);
  }

  .pageheader.hero div p {
    font-size: 16px;
    line-height: 26px;
    white-space: normal;
  }

  .pageheader.pageheadermeta.hero div:last-child {
    margin-bottom: 0;
  }

  .pageheader h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .pageheader,
  .pageheader.pageheadermeta {
    min-height: calc(120px + 30vh);
    padding-left: 5vw;
    padding-right: 5vw;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-auto-rows: min-content;
    padding-bottom: 1rem;
  }

  .pageheader div p {
    margin-top: 0;
    margin-bottom: 0px;
  }

  .pageheader div:last-child,
  .pageheader.pageheadermeta div:last-child {
    margin-bottom: 10px;
  }

  .pageheader.centered div,
  .pageheader.pageheadermeta.centered div {
    align-items: flex-start;
    text-align: left;
  }

  .pageheader.centered div p,
  .pageheader.pageheadermeta.centered div p {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .pageheader.coverPhoto {
    min-height: 0;
    padding-bottom: 0;

    div:last-child {
      margin-bottom: 0;
    }
  }

  .spacer {
    margin-top: 55px;
  }
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .pageheader {
    padding-bottom: 20px;
  }

  .pageheader.hero div h1 {
    margin-bottom: 0;
    margin-top: 80px;
    font-size: 80px;
    line-height: 110%;
    max-width: 700px;
  }

  .pageheader h1 {
    font-size: 72px;
    line-height: 110%;
  }

  .spacer {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1920px) {
  .pageheader,
  .pageheader.hero,
  .pageheader.pageheadermeta.hero {
    max-width: 1714px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
